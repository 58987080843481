import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
	margin: 0 auto;
	padding: 0 30px;
	@media (min-width: 768px) {
		width: 750px;
	}
	@media (min-width: 992px) {
		width: 970px;
	}
	@media (min-width: 1200px) {
		width: 1170px;
		padding: 0 10px;
	}
`;

const PageContainer = ({children}) => {
	return (
		<Container>{ children }</Container>
	)
};

PageContainer.propTypes = {
	children: PropTypes.node.isRequired
};

export default PageContainer;
