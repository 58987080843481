export const test = (singlePages) => {
  return singlePages.reduce((obj, page) => {
    if (typeof obj[page.langKey] === "undefined") {
      obj[page.langKey] = {}
    }
    const type = `Craft_${page.type}_${page.type}_Entry`
    obj[page.langKey][type] = page.slug
    return obj
  }, {})
}

const getSinglePages = () => {
  const stringSinglePages = localStorage.getItem("singlePages")
  const json = JSON.parse(stringSinglePages)
  if (!json) {
    return null
  }
  return json.reduce((obj, page) => {
    if (typeof obj[page.langKey] === "undefined") {
      obj[page.langKey] = {}
    }
    const type = `Craft_${page.type}_${page.type}_Entry`
    obj[page.langKey][type] = page.slug
    return obj
  }, {})
}

const getChannel = (localStorageTokenName) => {
  return JSON.parse(localStorage.getItem(localStorageTokenName))
}

const getChannelOtherLangUrl = (slug, otherLang) => {
  const channels = ["academyPages", "caseStudyPages"]
  let url = ""
  channels.forEach(channel => {
    const pages = getChannel(channel)
    const currentPage = pages.find(page => page.slug === slug)
    if (!currentPage) {
      return
    }
    const otherLangPage = pages.find(page => page.id === currentPage.id && page.langKey === otherLang)
    if (!otherLangPage) {
      return
    }
    url = otherLangPage.slug
  })
  return url
}

export const urlToPage = (lang = "pl") => {
  if (typeof window === "undefined") {
    return ""
  }
  const singlePages = getSinglePages()
  return singlePages ? singlePages[lang] ? singlePages[lang] : singlePages["pl"] : []
}

export const urlToOtherLang = (slug, currentLang = "pl", otherLang = "en") => {
  if (typeof window === "undefined") {
    return ""
  }
  const singlePages = getSinglePages()
  if (!singlePages) {
    return ""
  }
  const currentSlugs = singlePages[currentLang]
  const currentType = Object.keys(currentSlugs).find(type => {
    return currentSlugs[type] === slug
  })
  if (currentType) {
    return singlePages[otherLang][currentType]
  }
  return getChannelOtherLangUrl(slug, otherLang)
}

export const saveUrlsToLocalStorage = pageContext => {
  if (typeof window !== "undefined") {
    if (pageContext && pageContext.singlePages) {
      localStorage.setItem("singlePages", JSON.stringify(pageContext.singlePages))
    }
    if (pageContext && pageContext.academyPages) {
      localStorage.setItem("academyPages", JSON.stringify(pageContext.academyPages))
    }
    if (pageContext && pageContext.caseStudyPages) {
      localStorage.setItem("caseStudyPages", JSON.stringify(pageContext.caseStudyPages))
    }
  }
}
