import React, {useState, useEffect} from "react"
import styled from "styled-components"
import PageContainer from "../pageContainer"
import Logo from "./logo/logo"
import Nav from "./nav/nav"
import { Sticky } from "react-sticky"
import Menu from "./menu/menu"
import { PoseGroup } from "react-pose"
import pose from "react-pose"
import { useStaticQuery, graphql } from "gatsby"
import { connect } from "react-redux"
import { toggleMenu } from "../../state/app"
import { LocalizationContext } from "../../layout/layout"

const MyHeader = styled.header`
	background-color: ${({ theme, inverted }) => inverted ? theme.colors.mainWhite : theme.colors.mainPurple};
	overflow: hidden;
	z-index: 999;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
`

const StyledNavigationWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 40px 0;
	transition: all 0.4s ease;
	&.is-sticky {
	  padding: 5px 0;
	}
`

const PosedMenuWrapper = pose.div({
  enter: {
    y: 0,
    delayChildren: 100,
    staggerChildren: 50,
    staggerDirection: -1,
    transition: {
      y: { type: "spring", stiffness: 1000, damping: 70 },
      default: { duration: 100 },
    },
  },
  exit: {
    staggerChildren: 20,
    transition: {
      default: { delay: 300, duration: 250 },
    },
    y: "-120%",
  },
})

const StyledMenuWrapper = styled(PosedMenuWrapper)`
  background-color: ${({ theme }) => theme.colors.mainTextLightBlue};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  border: ${({ theme }) => "12px solid " + theme.colors.mainTextNavyBlue} 
`

const StyledMenuAnimation = styled.div`
position: fixed;
z-index: 9999;
top: 0;
`


const Header = ({ isMenuVisible, topColors, dispatch, localize }) => {
  const [isStickyVisible, setStickyVisible] = useState(false);
  const { lang } = localize;
  const data = useStaticQuery(graphql`
    query{
      menu: allCraftArcheeMenuArcheeMenuEntry(filter: { language: { eq: "pl" } }) {
        nodes{
          __typename
          title
          archeeWybierzPodstrone {
            __typename
            title
          }
          remoteParent {
            title
          }
          remoteChildren {
            ... on Craft_archeeMenu_archeeMenu_Entry {
              title
              archeeWybierzPodstrone {
                __typename
              }
            }
          }
        }
      }
      menuEn: allCraftArcheeMenuArcheeMenuEntry(filter: { language: { eq: "en" } }) {
        nodes{
          __typename
          title
          archeeWybierzPodstrone {
            __typename
            title
          }
          remoteParent {
            title
          }
          remoteChildren {
            ... on Craft_archeeMenu_archeeMenu_Entry {
              title
              archeeWybierzPodstrone {
                __typename
              }
            }
          }
        }
      }
      submenu: allCraftArcheeMenuMenuHomeEntry(filter: { language: { eq: "pl" } }) {
        nodes{
          __typename
          title
          archeeWybierzPodstrone {
            __typename
            title
          }
        }
      }
      submenuEn: allCraftArcheeMenuMenuHomeEntry(filter: { language: { eq: "en" } }) {
        nodes{
          __typename
          title
          archeeWybierzPodstrone {
            __typename
            title
          }
        }
      }
      globals: allCraftArcheePrzyciskNaHomeGlobalSet(filter: { language: { eq: "pl" } }) {
        nodes {
          archeePrzyciskHomeLink {
            __typename
            title
          }
          archeePrzyciskHomeTekst
        }
      }
      globalsEn: allCraftArcheePrzyciskNaHomeGlobalSet(filter: { language: { eq: "en" } }) {
        nodes {
          archeePrzyciskHomeLink {
            __typename
            title
          }
          archeePrzyciskHomeTekst
        }
      }
    }
  `);
  useEffect(() => {
    setStickyVisible(true);
  },[])

  const inverted = topColors !== "fiolety"
  const globalsData = lang === 'pl' ? data.globals?.nodes || [] : data.globalsEn?.nodes || [];
  const menuBtn = (globalsData && globalsData[0]) || ""
  const menuData = lang === 'pl' ? data.menu?.nodes || [] : data.menuEn?.nodes || [];
  const submenuData = lang === 'pl' ? data.submenu?.nodes || [] : data.submenuEn?.nodes || [];

  return (
      <>
        {isStickyVisible && <Sticky disableCompensation={true}>
          {({ style, isSticky }) => <MyHeader style={style} inverted={inverted}>
            <PageContainer>
              <StyledNavigationWrapper className={isSticky ? "is-sticky" : ""}>
                <Logo isSticky={isSticky} inverted={inverted} langKey={localize.lang}/>
                <Nav isSticky={isSticky}
                     toggleMenu={() => dispatch(toggleMenu(!isMenuVisible))}
                     submenuData={submenuData}
                     menuBtn={menuBtn}
                     langKey={localize.lang}
                     inverted={inverted}/>
              </StyledNavigationWrapper>
            </PageContainer>
          </MyHeader>}
        </Sticky>}
        <StyledMenuAnimation>
          <PoseGroup>
            {isMenuVisible &&
                <StyledMenuWrapper key="menu">
                  <Menu id="menu" menuData={menuData}
                        toggleMenu={() => dispatch(toggleMenu(!isMenuVisible))}
                        hideMenu={() => dispatch(toggleMenu(!isMenuVisible))}/>
                </StyledMenuWrapper>}
          </PoseGroup>
        </StyledMenuAnimation>
      </>
  )
};



const ConnectedHeader = connect(state => ({
  isMenuVisible: state.app.isMenuVisible,
  topColors: state.app.topColors,
}), null)(Header)

const ContextedHeader = React.forwardRef((props, ref) => {
  return (
      <LocalizationContext.Consumer>
        {localize => <ConnectedHeader {...props} localize={localize} ref={ref}/>}
      </LocalizationContext.Consumer>
  )
})

export default ContextedHeader

