import React, {Component} from 'react'
import styled from "styled-components"
import {graphql, Link, useStaticQuery} from "gatsby"
import BiscuitPng from "../../images/biscuit.svg"
import { LocalizationContext } from "../../layout/layout"
import { urlToPage } from "../common/type-to-url-match/type-to-url-match"
import Cookies from 'js-cookie';
import { connect } from "react-redux"
import { showCookieBar } from "../../state/app"

const ContentWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 45px 120px 230px 120px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -180px;
  z-index: 100000;
  width: 531px;
  height: 531px;
  background-color: rgba(19, 3, 83, 0.65);
  border-radius: 50%;
  &.hide {
    display: none;
  }
  @media(min-width: 360px) {
    padding: 45px 100px 230px 110px;
  }
  @media(min-width: 450px) {
    padding: 45px 75px 230px 140px;
    transform: translateX(0);
    left: -90px;
    bottom: -180px;
  }
`;

const BiscuitImage = styled.img`
  width: 53px;
  height: 50px;
  cursor: pointer;
`
const StyledInfo = styled.p`
  color: ${({ theme }) => theme.colors.mainTextLightBlue};
  font-size: 14px;
  line-height: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
`

const StyledButton = styled.button`
  padding: 10px 20px;
  border-radius: 23px;
  min-width: 65px;
  border: 2px solid ${({ theme }) => theme.colors.mainTextLightBlue};
  background: none;
  color: ${({ theme }) => theme.colors.mainWhite};
  font-family: 'Signika Negative', sans-serif;
  font-weight: ${ ({ theme }) => theme.fonts.weight.semiBold };
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  &.decline {
    border: 2px solid ${({ theme }) => theme.colors.mainWhite};
    margin-left: 15px;
  }   
`
const ReadMoreLink = styled(Link)`
	text-decoration: underline;
	font-size: 14px !important;
  font-weight: ${ ({ theme }) => theme.fonts.weight.bold };
	font-family: ${({ theme }) => theme.fonts.textFont}; !important;
	color: ${({ theme }) => theme.colors.mainTextLightBlue};
`

class CookieBar extends Component {
  state = {
    showBar: false
  }
  componentDidMount() {
    this.setState( {showBar: ((Cookies.get('isPopupShown') === undefined) || (this.props.isCookieBarShown === true)),})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const newProps = this.props
    if(prevProps.isCookieBarShown !== newProps.isCookieBarShown) {
      this.setState({ showBar: ((Cookies.get('isPopupShown') === undefined) || (this.props.isCookieBarShown === true)), })
    }
  }
  closeCookieBar() {
    const { dispatch } = this.props
    this.setState({ showBar: false })
    Cookies.set('isPopupShown', 0, {expires: 30});
    dispatch(showCookieBar(undefined))
  }
  render() {
    const { localize, cookieBarData } = this.props
    const { showBar } = this.state
    const globalDataDependsOnLang = localize.lang === "pl" ? cookieBarData?.cookieBar.nodes || "": cookieBarData?.cookieBarEn.nodes || ""
    const textBeforeLink = globalDataDependsOnLang?.[0]?.archeeCookiesTekstPrzedLinkiem || ""
    const textLink = globalDataDependsOnLang?.[0]?.archeeCookiesTekstLinku || ""
    const textAfterLink = globalDataDependsOnLang?.[0]?.archeeCookiesTekstPoLinku || ""
    const textButtonAccept = globalDataDependsOnLang?.[0]?.archeeCookiesNapisNaPrzyciskuAkceptuje || ""
    const textButtonNoAccept = globalDataDependsOnLang?.[0]?.archeeCookiesNapisNaPrzyciskuNieAkceptuje || ""
    const pokazuj = showBar;
    let additionalClassName = (pokazuj) ? "" : "hide";
  return(

    <ContentWrapper className={additionalClassName}>
      <BiscuitImage src={BiscuitPng}/>
      <StyledInfo>{textBeforeLink} <ReadMoreLink to={urlToPage(localize.lang)['Craft_archeePolitykaPrywatnosci_archeePolitykaPrywatnosci_Entry']}>{textLink}</ReadMoreLink><br/>
        {textAfterLink}</StyledInfo>
      <ButtonWrapper>
      <StyledButton onClick={this.closeCookieBar.bind(this)}>{textButtonAccept}</StyledButton>
        <StyledButton onClick={this.closeCookieBar.bind(this)} className="decline">{textButtonNoAccept}</StyledButton>
      </ButtonWrapper>
    </ContentWrapper>
  )}
}

const ConnectedCookieBar = connect(state => ({
  isCookieBarShown: state.app.isCookieBarShown,
}), null)(CookieBar)

const LocalizedCookieBar = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query {
        cookieBar: allCraftArcheeCookiesBarGlobalSet(filter: { language: { eq: "pl" } }) {
          nodes {
            archeeCookiesNapisNaPrzyciskuAkceptuje
            archeeCookiesNapisNaPrzyciskuNieAkceptuje
            archeeCookiesTekstLinku
            archeeCookiesTekstPoLinku
            archeeCookiesTekstPrzedLinkiem
          }
        }
        cookieBarEn: allCraftArcheeCookiesBarGlobalSet(filter: { language: { eq: "en" } }) {
          nodes {
            archeeCookiesNapisNaPrzyciskuAkceptuje
            archeeCookiesNapisNaPrzyciskuNieAkceptuje
            archeeCookiesTekstLinku
            archeeCookiesTekstPoLinku
            archeeCookiesTekstPrzedLinkiem
          }
        }
    }
  `);
  return (
      <LocalizationContext.Consumer>
        {localize => <ConnectedCookieBar  {...props} cookieBarData={data} localize={localize} ref={ref}/>}
      </LocalizationContext.Consumer>
  )
})

export default LocalizedCookieBar

