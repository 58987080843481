import React, { Component } from "react"
import { Link } from "gatsby"
import pose from "react-pose"
import styled from "styled-components"
import MenuItemActiveSVG from "../../../../icons/brand/menu-item-active-ico.svg"
import SubmenuItemActiveSVG from "../../../../icons/brand/submenu-item-active-ico.svg"

const PosedMenuItemWrapper = pose.li({
  enter: {
    y: 0, opacity: 1,
  },
  exit: {
    y: -600, opacity: 0,
  },
})
const StyledMenuItemWrapper = styled(PosedMenuItemWrapper)`
  position: relative;
`
const StyledMenuItem = styled(Link)`
  font-size: 30px;
  line-height: 35px;
  font-family: ${({ theme }) => theme.fonts.headerFont};
  color: ${({ theme }) => theme.colors.mainTextNavyBlue};
  font-weight: 900;
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  padding: 0;
  @media( min-width: 768px ) { 
    font-size: 40px;
    line-height: 45px;
  }
  @media( min-width: 992px ) { 
    font-size: 50px;
    line-height: 55px;
  }
  @media( min-width: 1200px ) { 
    font-size: 57px;
    line-height: 66px;
  }
  &:hover {
    opacity: 0.6;
  }
  &.active {
    opacity: 1;
    color: ${({ theme }) => theme.colors.mainTextWhite}; 
    svg {
      display: block;
    }
  }
  svg {
    display: none;
    position: absolute;
    right: calc( 100% + 5px );
    top: 50%;
    transform: translateY(-50%) scale(0.7);
    @media(min-width: 768px){
       transform: translateY(-50%) scale(0.8);
    }
  }
`
const PosedSubmenuWrapper = pose.ul({
  closed: {
    maxHeight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  opened: {
    paddingTop: 10,
    paddingBottom: 20,
    maxHeight: "300px",
  },
})
const StyledSubmenuWrapper = styled(PosedSubmenuWrapper)`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0 0 0 25px;
  margin-left: -25px;
  overflow: hidden;
`
const StyledSubmenuItem = styled(StyledMenuItem)`
  // color: ${({ theme }) => theme.colors.mainTextWhite};
  font-family: ${({ theme }) => theme.fonts.textFont};
  font-size: 16px;
  line-height: 30px; 
  font-weight: 400;
  @media( min-width: 768px ) { 
    font-size: 20px;
    line-height: 30px; 
  }
  &.active {
    opacity: 1;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.mainTextNavyBlue}; 
  }
  svg {
    right: calc( 100% + 15px );
  }
`

class MenuItem extends Component {

  submenuElements = []
  state = {
    isSubmenuItemActive: false,
    isSubmenuOpened: false,
  }

  componentDidMount() {
    this.checkIfSubmenuActive()
  }

  toggleSubmenu() {
    this.setState({ isSubmenuOpened: !this.state.isSubmenuOpened })
  }

  checkIfSubmenuActive() {
    let submenuActive = false
    this.submenuElements.forEach((element) => {
      if (element.classList && element.classList.contains("active")) {
        submenuActive = true
      }
    })
    if (submenuActive) {
      this.setState({ isSubmenuItemActive: true, isSubmenuOpened: true })
    }
  }

  render() {
    const { to, text, submenu, toggleMenu } = this.props
    const isSubmenu = submenu && submenu.length > 0
    const isSubmenuItemActive = isSubmenu && this.state.isSubmenuItemActive
    const isSubmenuOpened = isSubmenu && this.state.isSubmenuOpened
    return (
      <StyledMenuItemWrapper>
        {to && <StyledMenuItem activeClassName="active" onClick={toggleMenu} to={to}>
          <MenuItemActiveSVG/>
          <span>{text}</span>
        </StyledMenuItem>}
        {!to && <StyledMenuItem as="button" onClick={this.toggleSubmenu.bind(this)}
                                className={isSubmenuItemActive ? "active" : ""}>
          <MenuItemActiveSVG/>
          <span>{text}</span>
        </StyledMenuItem>}
        {isSubmenu && <StyledSubmenuWrapper pose={isSubmenuOpened ? "opened" : "closed"}>
          {submenu.map((submenuItem, key) =>
            <StyledSubmenuItem activeClassName="active" to={submenuItem.to} onClick={toggleMenu}
                               ref={submenuElement => this.submenuElements.push(submenuElement)} key={key}>
              <SubmenuItemActiveSVG/>
              <span>{submenuItem.text}</span>
            </StyledSubmenuItem>)}
        </StyledSubmenuWrapper>}
      </StyledMenuItemWrapper>
    )
  }
}

export default MenuItem
