import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import { theme } from "../utils/theme"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { StickyContainer } from "react-sticky"
import Footer from "../components/footer/footer"
import "animate.css/animate.min.css"
import Header from "../components/header/header"
import CookieBar from "../components/cookieBar/cookieBar"

const GlobalStyles = createGlobalStyle`
	body {
		margin: 0;
	  background-color: ${({ theme }) => theme.colors.mainWhite};
		font-family: ${({ theme }) => theme.fonts.textFont};
		padding-top: 130px;
		width: 100%;
		overflow-x: hidden;
		@media( min-width: 768px ) {
		  padding-top: 150px;
		}
	}
	h1, h2, h3, h4, h5, h6 {
		font-family: ${({ theme }) => theme.fonts.headerFont};
		margin: 0; 
		p {
		  font-size: inherit;
		  line-height: inherit;
		  font-family: inherit;
		}
	}
	p {
		font-family: 'Lato', sans-serif;
		margin: 0;
	}
	ul {
	  list-style-type: none;
	  padding: 0;
	  margin: 0;
	}
	* {
	  box-sizing: border-box;
	}
	
	img {
	  pointer-events: none;
	}
	
	input {
	  -webkit-appearance: none;
	}
	
	.tl-edges {
	  overflow: hidden;
	}
`

export const LocalizationContext = React.createContext({ lang: "pl", slug: "/" })

const Layout = (props) => {
  const { children, pageContext } = props
    const { langKey, slug } = pageContext
    // console.log(props);
    return (
    <ThemeProvider theme={theme}>
      <LocalizationContext.Provider value={{ lang: langKey, slug }}>
        <StickyContainer>
          <GlobalStyles/>
          <Header/>
              <main>
                {children}
              </main>
          <Footer/>
          <CookieBar/>
        </StickyContainer>
      </LocalizationContext.Provider>
    </ThemeProvider>
  )
}

export default Layout
