import React, { Component } from "react"
import styled from "styled-components"
import PageContainer from "../pageContainer"
import ImageHero from "../../images/hero_footer.svg"
import {Link, useStaticQuery, graphql} from "gatsby"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import { urlToPage } from "../common/type-to-url-match/type-to-url-match"
import { LocalizationContext } from "../../layout/layout"

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
  background-image: url(${ImageHero});
  background-repeat: no-repeat;
  background-position-y: 117%;
  background-position-x: 80%;
  background-size: 50% 27%;
  @media(min-width: 768px){
    background-position-y: 118%;
  }
  @media(min-width: 1200px){
    background-position-y: 125%;
    background-position-x: 100%;
    background-size: 50% 35%;
  }

`

const StyledLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.mainTextNavyBlue}; 
  margin-top: 50px;
  margin-bottom: 50px;
  @media(min-width: 1200px) {
    margin-bottom: 70px;  
  }
`

const StyledHeaderH2 = styled.h2`
  color: ${({ theme }) => theme.colors.mainTextFooterNavy};
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 25px;
  width: 100%;
  text-align: left;
`

const StyledDiv = styled.div`
  color: ${({ theme }) => theme.colors.mainTextFooterNavy};
  font-size: 14px;
  text-align: left;
  width: 100%;
  line-height: 23px;
  margin: 0;
`

const StyledSubpageLink = styled(Link)`
  color: ${({ theme }) => theme.colors.mainTextFooterNavy};
  font-size:14px;
  line-height: 23px;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
`

const StyledLink = styled.p`
  color: ${({ theme }) => theme.colors.mainTextFooterNavy};
  font-size: 14px;
  line-height: 23px;
  text-align: left;
  margin-bottom: 10px;
  cursor: pointer;

`

const StyledBlockContactUs = styled.div`
  width: 100%;
  margin-bottom: 25px;
  @media(min-width: 1200px) {
    width: 25%;
  }
`

const StyledBlockArchee = styled.div`
  width: 100%;
  margin-bottom: 25px;
  @media(min-width: 1200px) {
    width: 40%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  } 
`

const StyledBlockArcheeTwoColumns = styled.div`
  width: 100%;
  @media(min-width: 1200px) {
    display: flex;
    & > div {
      width: 50%;
    }
}
`

const StyledBlockOthers = styled.div`
  width: 50%;  
  @media(min-width: 1200px) {
    width: 20%;
  }
`

const StyledBlockFindUs = styled.div`
  width: 100%;
  @media(min-width: 1200px) {
    width: 15%;
  }
`

const StyledImageMedia = styled.img` 
  width: 26px;
  height: 26px;
  margin-right: 10px;
  cursor: pointer;
`

const StyledA = styled.a`
  text-decoration: none;
`


class Footer extends Component {

  render() {
    const { localize, footerData } = this.props
    const footerDependsOnLang = localize.lang === "pl" ? footerData.footer?.nodes  || "" : footerData.footerEn?.nodes || ""
    const socialMediaDependsOnLang = localize.lang === "pl" ? footerData.socialMedia?.nodes || "" :  footerData.socialMediaEn?.nodes || ""
    const stopka =  footerDependsOnLang[0] || ''
    const socialMedia = socialMediaDependsOnLang?.[0] || ''
    const columnTitle = stopka.archeeStopkaContactUs?.[0]?.naglowek || ""
    const columnDescription = stopka.archeeStopkaContactUs?.[0]?.opis || ""
    const firstColumnTitle = stopka.archeeStopkaMapaStrony1Kolumna?.[0]?.naglowek || ""
    const firstColumnSubpages = stopka.archeeStopkaMapaStrony1Kolumna?.[0]?.podstrony || []
    const secondColumnTitle = stopka.archeeStopkaMapaStrony2Kolumna?.[0]?.naglowek || []
    const secondColumnSubpages = stopka.archeeStopkaMapaStrony2Kolumna?.[0]?.podstrony || []
    const thirdColumnTitle = stopka.archeeStopkaMapaStrony3Kolumna?.[0]?.naglowek || ""
    const thirdColumnSubpages = stopka.archeeStopkaMapaStrony3Kolumna?.[0]?.wybierzPodstrony || []
    const fourthColumnTitle = stopka.archeeStopkaMapaStrony4Kolumna?.[0]?.naglowek || ""
    const fourthColumnLink = stopka.archeeStopkaMapaStrony4Kolumna?.[1]?.podstronaOpis || ""
    const socialMediaIcons = socialMedia?.archeeSocialMedia || []
    return (
      <PageContainer>
        <StyledWrapper>
          <StyledLine/>
          <StyledBlockContactUs>
            <StyledHeaderH2>{columnTitle}</StyledHeaderH2>
            <StyledDiv x-ms-format-detection="none" dangerouslySetInnerHTML={getHtmlFromRedactorField(columnDescription)}/>
          </StyledBlockContactUs>
          <StyledBlockArchee>
            <StyledBlockArcheeTwoColumns>
              <div>
                <StyledHeaderH2>{firstColumnTitle}</StyledHeaderH2>
                {firstColumnSubpages.map((item, key) => <div key={key}><StyledSubpageLink
                  to={urlToPage(localize.lang)[item.__typename]}>{item.title}</StyledSubpageLink></div>)}
              </div>
              <div>
                <StyledHeaderH2>{secondColumnTitle}</StyledHeaderH2>
                {secondColumnSubpages.map((item, key) => <div key={key}><StyledSubpageLink
                  to={urlToPage(localize.lang)[item.__typename]}>{item.title}</StyledSubpageLink></div>)}
              </div>
            </StyledBlockArcheeTwoColumns>
          </StyledBlockArchee>
          <StyledBlockOthers>
            <StyledHeaderH2>{thirdColumnTitle}</StyledHeaderH2>
            {thirdColumnSubpages.map((item, key) => <div key={key}><StyledSubpageLink
              to={urlToPage(localize.lang)[item.__typename]}>{item.title}</StyledSubpageLink></div>)}
          </StyledBlockOthers>
          <StyledBlockFindUs>
            <StyledHeaderH2>{fourthColumnTitle}</StyledHeaderH2>
            <StyledA href={"https://" + fourthColumnLink} target="_blank">
              <StyledLink>{fourthColumnLink}</StyledLink>
            </StyledA>
            {socialMediaIcons.map((item, key) => <StyledA key={key} href={item.socialLink} target="_blank"> <StyledImageMedia
              href={socialMediaIcons.socialLink} src={item.ikona[0].url}/></StyledA>)}
          </StyledBlockFindUs>
        </StyledWrapper>
      </PageContainer>
    )
  }
}

const LocalizedFooter = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query {
      footer: allCraftArcheeStopkaGlobalSet(filter: { language: { eq: "pl" } }) {
        nodes {
          archeeStopkaContactUs {
            ... on Craft_archeeStopkaContactUs_wpis_BlockType {
              naglowek
              opis
            }
          }
          archeeStopkaMapaStrony1Kolumna {
            ... on Craft_archeeStopkaMapaStrony1Kolumna_wpis_BlockType {
              naglowek
              podstrony {
                title
                __typename
              }
            }
          }
          archeeStopkaMapaStrony2Kolumna {
            ... on Craft_archeeStopkaMapaStrony2Kolumna_wpis_BlockType {
              naglowek
              podstrony {
                title
                __typename
              }
            }
          }
          archeeStopkaMapaStrony3Kolumna {
            ... on Craft_archeeStopkaMapaStrony3Kolumna_wpis_BlockType {
              naglowek
              wybierzPodstrony {
                title
                __typename
              }
            }
          }
          archeeStopkaMapaStrony4Kolumna {
            ... on Craft_archeeStopkaMapaStrony4Kolumna_naglowek_BlockType {
              naglowek
            }
            ... on Craft_archeeStopkaMapaStrony4Kolumna_podstrona_BlockType {
              podstronaOpis
            }
          }
        }
      }
      footerEn: allCraftArcheeStopkaGlobalSet(filter: { language: { eq: "en" } }) {
        nodes {
          archeeStopkaContactUs {
            ... on Craft_archeeStopkaContactUs_wpis_BlockType {
              naglowek
              opis
            }
          }
          archeeStopkaMapaStrony1Kolumna {
            ... on Craft_archeeStopkaMapaStrony1Kolumna_wpis_BlockType {
              naglowek
              podstrony {
                title
                __typename
              }
            }
          }
          archeeStopkaMapaStrony2Kolumna {
            ... on Craft_archeeStopkaMapaStrony2Kolumna_wpis_BlockType {
              naglowek
              podstrony {
                title
                __typename
              }
            }
          }
          archeeStopkaMapaStrony3Kolumna {
            ... on Craft_archeeStopkaMapaStrony3Kolumna_wpis_BlockType {
              naglowek
              wybierzPodstrony {
                title
                __typename
              }
            }
          }
          archeeStopkaMapaStrony4Kolumna {
            ... on Craft_archeeStopkaMapaStrony4Kolumna_naglowek_BlockType {
              naglowek
            }
            ... on Craft_archeeStopkaMapaStrony4Kolumna_podstrona_BlockType {
              podstronaOpis
            }
          }
        }
      }
      socialMedia: allCraftArcheeSocialMediaGlobalSet(filter: { language: { eq: "pl" } }) {
        nodes {
          archeeSocialMedia {
            ... on Craft_archeeSocialMedia_socialMedia_BlockType {
              ikona {
                url
              }
              socialLink
            }
          }
        }
      }
      socialMediaEn: allCraftArcheeSocialMediaGlobalSet(filter: { language: { eq: "en" } }) {
        nodes {
          archeeSocialMedia {
            ... on Craft_archeeSocialMedia_socialMedia_BlockType {
              ikona {
                url
              }
              socialLink
            }
          }
        }
      }
    }
  `);
  return (
      <LocalizationContext.Consumer>
        {localize => <Footer {...props} localize={localize} footerData={data} ref={ref}/>}
      </LocalizationContext.Consumer>
  )
})

export default LocalizedFooter;
