const initialState = {
  isMenuVisible: false,
  isSubmenuVisible: false,
  topColors: "fiolety",
  isCookieBarShown: undefined,

}

const TOGGLE_MENU = "TOGGLE_MENU"
const TOGGLE_SUBMENU = "TOGGLE_SUBMENU"
const SET_TOP_COLORS = "SET_TOP_COLORS"
const TOGGLE_LANGUAGE = "TOGGLE_LANGUAGE"
const SHOW_COOKIE_BAR = "SHOW_COOKIE_BAR"

export const toggleMenu = isMenuVisible => ({
  type: TOGGLE_MENU, isMenuVisible,
})

export const toggleSubmenu = isSubmenuVisible => ({
  type: TOGGLE_SUBMENU, isSubmenuVisible,
})

export const setTopColors = topColors => ({
  type: SET_TOP_COLORS, topColors,
})

export const toggleLanguage = isLanguageSwitcherVisible => ({
  type: TOGGLE_LANGUAGE, isLanguageSwitcherVisible,
})

export const showCookieBar = isCookieBarShown => ({
  type: SHOW_COOKIE_BAR, isCookieBarShown,
})


export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return { ...state, isMenuVisible: action.isMenuVisible }
    case TOGGLE_SUBMENU:
      return { ...state, isSubmenuVisible: action.isSubmenuVisible }
    case SET_TOP_COLORS:
      return { ...state, topColors: action.topColors }
    case TOGGLE_LANGUAGE:
      return { ...state, isLanguageSwitcherVisible: action.isLanguageSwitcherVisible }
    case SHOW_COOKIE_BAR:
      return { ...state, isCookieBarShown: action.isCookieBarShown }
    default:
      return state
  }
};
